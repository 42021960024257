import { useEffect } from 'react';

import { URLStore } from '../stores/url-store';
import type { HelpQuestion } from '../api/help';

/**
 * Find or create link canonical element
 * @returns {HTMLLinkElement}
 */
const getOrCreateCanonical = (): HTMLLinkElement => {
  // get existing element
  const link = document.querySelector('link[rel=canonical]');

  if (link != null && link instanceof HTMLLinkElement) {
    return link;
  }

  // create new link element
  const element = document.createElement('link');
  element.rel = 'canonical';

  // add to document head
  const head = document.querySelector('head');
  if (head != null) {
    head.appendChild(element);
  }

  return element;
};

/**
 * Add canonical link to header of the page for the passed question.
 *
 * @param {Question} question - question for the canonical
 */
export const useCanonical = (question: HelpQuestion | null): void => {
  useEffect(() => {
    // get element
    const element = getOrCreateCanonical();

    // remove element if there is no question
    if (question == null) {
      element.remove();
      return;
    }

    // create path for the question
    const path = question.url;

    // set absolute url
    element.href = URLStore.buildAbsolutePath(path);

    // return cleanup function
    return () => {
      element.remove();
    };
  }, [question]);
};
